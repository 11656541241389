<template>
  <div class="greenEnergy_box">
    <div class="head_nav">
      <div class="title">- 绿能派 -</div>
      <div class="slogan">每人每天省一度电</div>
    </div>
    <div class="content_nav">
      <div class="app_scenario">
        <div class="label_box">
          <div class="label">应用场景</div>
          <div class="en_label">Application scenario</div>
          <div class="spherical"></div>
        </div>
        <div class="flat_patternmaking_box">
          <img src="https://sys.huwei.xin:7443/huwei-website/mini_app_flat_patternmaking_img.png" alt="">
        </div>
        <div class="menu_box">
          <div class="function_box">
            <div class="label">主要功能</div>
            <div class="en_label">The main function</div>
          </div>
          <div class="item_box">
            <div class="item" v-for="item in menuList" :key="item.icon">
              <img :src="('https://sys.huwei.xin:7443/huwei-website/icon/' + item.icon)" alt="">
              <div class="label">{{ item.label }}</div>
              <div class="description">{{ item.description }}</div>
            </div>
          </div>
        </div>
        <div class="label_box member_rights">
          <div class="label">成员权益</div>
          <div class="en_label">Member rights</div>
          <div class="rights_menu">
            <div class="text_box">
              <div class="slogan">捐赠积分献大爱</div>
              <div class="text_title">微公益</div>
            </div>
            <div class="img_box">
              <img src="https://sys.huwei.xin:7443/huwei-website/public_benefit_img.png" alt="">
            </div>
            <div class="text_box">
              <div class="slogan">免费酒店自由飞</div>
              <div class="text_title">免费出行</div>
            </div>
            <div class="img_box">
              <img src="https://sys.huwei.xin:7443/huwei-website/trip_img.png" alt="">
            </div>
            <div class="img_box">
              <img src="https://sys.huwei.xin:7443/huwei-website/gift_img.png" alt="">
            </div>
            <div class="text_box">
              <div class="slogan">海量礼品自由换</div>
              <div class="text_title">礼品兑换</div>
            </div>
            <div class="img_box">
              <img src="https://sys.huwei.xin:7443/huwei-website/gifts_img.png" alt="">
            </div>
            <div class="text_box">
              <div class="slogan">免费多次领取备份</div>
              <div class="text_title">免费好礼</div>
            </div>
          </div>
        </div>
        <div class="popularize_nav">
          <div class="text_bg">PUBLIC WELFARE</div>
          <div class="function_box">
            <div class="label">微公益</div>
            <div class="en_label">Micro public welfare</div>
          </div>
          <div class="content_box">
            <div class="img_box">
              <img class="img1" src="https://sys.huwei.xin:7443/huwei-website/swipper/01.jpg" alt="绿能派-微公益">
              <div class="inline_img">
                <img class="img2" src="https://sys.huwei.xin:7443/huwei-website/swipper/04.jpg" alt="绿能派-微公益">
                <img class="img3" src="https://sys.huwei.xin:7443/huwei-website/swipper/06.png" alt="绿能派-微公益">
              </div>
            </div>
            <div class="text_content">
              <div class="title">“逐梦成长 筑梦未来”主题捐助活动</div>
              <div class="content">
                杭州互为综合能源服务有限公司，始终秉承“源于社会回馈社会”的社会责任理念，大力倡导和发展绿色低碳事业，积极发起并参与教育、环保、慈善等社会公益项目。<br/><br/>
                活动以宣传  “每人每天省一度电”  为中心，教育孩子们从小低碳生活安全用电的同时，还给安民乡中心小学的小朋友们带来了许多生活及学习的用品。<br/><br/>
                相信未来，杭州互为能够帮助到更多像安民乡中心小学一样的学校，继续拓展帮扶渠道，丰富帮扶载体，加大帮扶深度和力度，为改造乡村学校教育环境做出贡献，
                更希望能够通过努力，感召更多的社会力量和爱心人士，参与到帮助山区学校和孩子的公益事业中，让学校条件越来越好，让孩子们都能走出山区。
              </div>
              <div class="slogan">为实现“每人每天省一度电”的愿景，推广“绿能派”移动应用，建立绿能积分体系，为参与绿能派成员积累碳资产。</div>
              <div class="more">更多公益筹备中…</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useStore } from "vuex";
import { computed, ref, getCurrentInstance   } from "vue";
export default {
  name: "greenEnergy",
  components: {
  },
  setup() {
    const dom = getCurrentInstance()
    const state = computed(() => useStore().state.client);
    const menuList = ref([
      {
        icon: 'signing_center_icon.png',
        label: '签住中心',
        description: '用户在酒店登记入住和退房时，同步在产品上签住和签出，动态展示客房用电数据及能耗排名，预计绿能积分。'
      },
      {
        icon: 'point_management_icon.png',
        label: '积分管理',
        description: '住宿期间预计绿能积分，退房签出后奖励积分，可随时用于积分兑换精美礼品，或捐献积分参加微公益。'
      },
      {
        icon: 'micro_charity_icon.png',
        label: '微公益',
        description: '由绿能派平台发起助学、环保等公益活动，所有用户参与共建，捐献积分不受限制，达成目标后在线下开展并公示结果。'
      },
    ])
    return {
      dom,
      state,
      menuList
    }
  }
}
</script>
<style lang="scss" scoped>
.greenEnergy_box {
  width: 1920px;
  .head_nav {
    background: url('https://sys.huwei.xin:7443/huwei-website/green_energy_head_bg.png') no-repeat 100% 100%;
    background-size: cover;
    width: 100%;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #ffffff;
    .title {
      font-size: 22px;
      line-height: 48px;
    }
    .slogan {
      font-size: 40px;
      line-height: 48px;
    }
  }
  .content_nav {
    position: relative;
    .app_scenario {
      .label_box {
        background: #ffffff;
        height: 733px;
        padding: 130px 200px;
        box-sizing: border-box;
        .label {
          font-size: 24px;
          font-weight: 800;
          color: #0F0F0F;
          line-height: 30px;
          border-left: 4px solid #1667FF;
          padding-left: 8px;
        }
        .en_label {
          font-size: 18px;
          font-weight: 600;
          color: #5E6070;
          line-height: 22px;
          padding: 8px 12px;
        }
        .spherical {
          position: relative;
          width: 229px;
          height: 229px;
          background: #1566FF50;
          border-radius: 50%;
          top: 100px;
          left: 282px;
        }
        .spherical::before {
          content: '';
          position: absolute;
          width: 57px;
          height: 57px;
          background: #1566FF;
          border-radius: 50%;
          top: -150px;
          left: 547px;
        }
        .spherical::after {
          content: '';
          position: absolute;
          width: 20px;
          height: 20px;
          // background: #1566FF;
          border: 6px solid #1566FF;
          border-radius: 50%;
          top: -80px;
          left: 627px;
        }
      }
      .flat_patternmaking_box {
        position: absolute;
        left: 50%;
        top: 625px;
        transform: translate(-50%, -50%);
        img {
          height: 797px;
        }
      }
      .menu_box {
        background: url('https://sys.huwei.xin:7443/huwei-website/app_scenario_bg.png') no-repeat 100% 100%;
        height: 829px;
        .function_box {
          padding: 283px 208px 80px;
          position: relative;
          float: right;
          .label {
            font-size: 24px;
            font-weight: 800;
            color: #0F0F0F;
            line-height: 30px;
            text-align: right;
            border-right: 4px solid #1667FF;
            padding-right: 8px;
          }
          .en_label {
            font-size: 18px;
            font-weight: 600;
            color: #5E6070;
            line-height: 22px;
            padding: 8px 12px;
          }
        }
        .item_box {
          width: 100%;
          display: grid;
          justify-content: center;
          padding: 0 216px;
          grid-gap: 35px;
          box-sizing: border-box;
          grid-template-columns: repeat(3, 1fr);
          .item {
            width: 473px;
            height: 301px;
            background: #FFFFFF;
            border-radius: 4px;
            padding: 40px 45px 35px;
            box-sizing: border-box;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            img {
              width: 60px;
              height: 60px;
            }
            .label {
              font-size: 24px;
              font-weight: 600;
              color: #000000;
              line-height: 33px;
              padding: 20px 0 10px;
            }
            .description {
              font-size: 18px;
              font-weight: 100;
              color: #000000;
              line-height: 28px;
              padding-top: 8px;
              text-align: left;
            }
          }
        }
      }
      .member_rights {
        height: 945px;
        .rights_menu {
          display: flex;
          width: 100%;
          flex-wrap: wrap;
          .text_box {
            display: flex;
            flex-direction: column;
            width: 377px;
            height: 271px;
            background: #FFFFFF;
            justify-content: center;
            align-items: center;
            .slogan {
              font-size: 24px;
              color: #3C3B3Bc9;
            }
            .text_title {
              font-size: 28px;
              font-weight: bold;
              padding-top: 15px;
              color: #333333;
            }
          }
          .img_box {
            img {
              width: 378px;
              height: 271px;
            }
          }
        }
      }
      .popularize_nav {
        height: 1351px;
        background: #FAFAFA;
        .text_bg {
          position: absolute;
          width: 883px;
          font-size: 200px;
          font-weight: bold;
          color: #F2F2F2;
          line-height: 180px;
          padding: 36px 20px;
        }
        .function_box {
          padding: 153px 208px 80px;
          position: relative;
          float: right;
          .label {
            font-size: 24px;
            font-weight: 800;
            color: #0F0F0F;
            line-height: 30px;
            text-align: right;
            border-right: 4px solid #1667FF;
            padding-right: 8px;
          }
          .en_label {
            font-size: 18px;
            font-weight: 600;
            color: #5E6070;
            line-height: 22px;
            padding: 8px 12px;
          }
        }
        .content_box {
          position: absolute;
          display: grid;
          grid-template-columns: repeat(2,1fr);
          padding: 269px 208px 0 52px;
          grid-gap: 210px;
          .img_box {
            text-align: end;
            .img1 {
              width: 668px;
              height: 437px;
            }
            .inline_img {
              position: relative;
              width: 820px;
              display: flex;
              padding-top: 32px;
              .img2 {
                width: 432px;
                height: 380px;
                padding-right: 40px;
              }
              .img3 {
                width: 346px;
                height: 454px;
              }
            }
          }
          .text_content {
            text-align: right;
            padding-top: 75px;
            .title {
              font-size: 24px;
              font-weight: 500;
              color: #474747;
              line-height: 33px;
            }
            .content {
              font-size: 18px;
              font-weight: 100;
              color: #474747;
              line-height: 25px;
              padding-top: 36px;
            }
            .slogan {
              font-size: 24px;
              font-weight: 600;
              color: #3D3F55;
              line-height: 34px;
              padding-top: 234px;
            }
            .more {
              width: 394px;
              height: 81px;
              border-radius: 10px;
              opacity: 0.29;
              border: 1px solid #1667FF;
              font-size: 28px;
              color: #4F8CFF;
              line-height: 38px;
              display: flex;
              justify-content: center;
              align-items: center;
              margin: 107px 110px
            }
          }
        }
      }
    }
  }
}
</style>